import React from "react"
import { WPApi } from "@api"
import {
  ResizedImage as Image,
  TextInput,
  AnimatedButton,
  AnimatedLink,
  AnimatedSection,
} from "@components/shared"
import icon from "./img/newsE.png"

import {
  news,
  news__wrapper,
  header,
  header__title,
  header__subtitle,
  form,
  form__input,
  form__button,
  form__msg,
  link__more,
} from "./styles/newsletter.module.scss"

import is from "is_js"

class NewsletterForm extends React.Component {
  state = {
    email: "",
    msg: "",
    imBusy: false,
  }

  action = () => {
    if (is.email(this.state.email) === true) {
      this.setState({
        imBusy: true,
      })
      WPApi.sendMail(this.state.email).then(({ message }) => {
        this.setState({
          email: "",
          msg: message,
          imBusy: false,
        })
      })
    }
  }

  render() {
    if (this.state.imBusy) {
      return (
        <div className={form}>
          <h6 className={form__msg}>Dodaję Cię do listy mailingowej</h6>
        </div>
      )
    }
    if (this.state.msg != "") {
      return (
        <div className={form}>
          <h6 className={form__msg}>{this.state.msg}</h6>
        </div>
      )
    }

    const {
      title = "Pozostań na biezaco, zapisz się do Newslettera",
      subtitle = "Będziesz otrzymywać informacje, aktualizacje i nową wiedzę",
    } = this.props

    return (
      <div className={news}>
        <img src={icon} alt="" />
        <div className={news__wrapper}>
          <div className={header}>
            <h3 className={header__title}>{title}</h3>
            {/* <h5 className={header__subtitle}>{subtitle}</h5> */}
          </div>

          <div className={form}>
            <TextInput
              type="email"
              className={form__input}
              value={this.state.email}
              placeholder="Wprowadź adres email"
              onChange={(ev) => {
                const { value } = ev.target
                this.setState({
                  email: value,
                })
              }}
            />
            <AnimatedButton className={form__button} onClick={this.action}>
              Wyślij
              <i className="icofont-double-right"></i>
            </AnimatedButton>
          </div>
          <a to={"/polityka-prywatnosci/"} className={link__more}>
            <small>
              * Zapisując się do newslettera zgadzasz się na przetwarzanie
              swoich informacji osobowych zgodnie z naszą Polityką Prywatności.
            </small>
          </a>
        </div>
      </div>
    )
  }
}

export const NewsletterSection = () => {
  return <NewsletterForm />
}

export default NewsletterSection
