import React, {
  useState,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useForwardedRef,
  useMemo,
} from "react"
import { useSpring, animated } from "react-spring/"

const eventHandlerGenerator = function (
  handlerName,
  phase,
  props,
  setter,
  cbfn = useCallback
) {
  return cbfn(() => {
    setter(phase)
    props[handlerName] && props[handlerName]()
  }, [props[handlerName]])
}

const nameStatePairs = {
  onIdle: "idle",
  onMouseLeave: "idle",
  onTouchCancel: "idle",

  onMouseEnter: "hover",

  onMouseDown: "clicking",
  onTouchStart: "clicking",
  onTouchMove: "clicking",

  onClick: "clicked",
}

const bindEvents = (props, setter) => {
  const events = {}
  Object.keys(nameStatePairs).forEach((key) => {
    if (key !== "onIdle") {
      events[key] = eventHandlerGenerator(
        key,
        nameStatePairs[key],
        props,
        setter
      )
    }
  })

  return events
}

const InteractWithUser = (props) => {
  const { children, onChangePhase } = props

  const [phase, setPhase] = useState(nameStatePairs.onIdle) // onhover || idle || onclick

  useEffect(() => {
    onChangePhase && onChangePhase(phase)
  }, [phase])

  const bounded = bindEvents(props, setPhase)

  return (
    <animated.div {...props} {...bounded}>
      {children}
    </animated.div>
  )
}

export default InteractWithUser
