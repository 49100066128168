import React from "react"
import { Modal } from "@components/shared"
import { AnimatedButton, AnimatedLink } from "@components/shared"
import Logo from "./Logo"

import {
  modal,
  modal__menu,
  modal__menu__item,
  header__menu,
} from "./header.module.scss"

const Toggler = ({ className, onClick }) => {
  return (
    <AnimatedButton className={className} onClick={onClick}>
      <i class="fas fa-bars"></i>
    </AnimatedButton>
  )
}

const options = [
  { url: "/shop", label: "Sklep" },
  { url: "/blog", label: "Blog" },
  { url: "/contact", label: "Kontakt" },
  { url: "/other-important-links", label: "Inne" },
]

class ModalMenu extends React.Component {
  modal = React.createRef()

  render() {
    const { className } = this.props

    return (
      <div className={header__menu}>
        <Toggler
          className={className}
          onClick={() => {
            this.modal.current.toggle()
          }}
        />
        <Modal title={""} ref={this.modal} className={modal}>
          <ul className={modal__menu}>
            <Logo />
            {options.map((el, k) => {
              return (
                <li key={k} className={modal__menu__item}>
                  <AnimatedLink to={`${el.url}/`}>{el.label}</AnimatedLink>
                </li>
              )
            })}
          </ul>
        </Modal>
      </div>
    )
  }
}

export default ModalMenu
