import React, { useState, useEffect, useCallback, useRef } from "react"
import { useTransition, animated, config } from "react-spring"

import {
  item__slider__wrapper,
  item__slider__element,
} from "./slider.module.scss"

const defaultSliderSize = 300

const DefaultSlide = () => {
  return <div>DefaultSlide</div>
}

const prepareStyles = (styles) => {
  return {
    opacity: styles.opacity,
    transform: styles.scale.interpolate(
      (s) => `scale3d(${s}, ${s}, 1) translateY(-50%)`
    ),
  }
}

const Slider = ({
  items = [],
  size = [defaultSliderSize, defaultSliderSize],
  Slide = DefaultSlide,
  observeValue = (item) => item.slug,
}) => {
  const [index, set] = useState(0)
  const ref = useRef(null)

  const transitions = useTransition(items[index], observeValue, {
    from: {
      opacity: 0,
      scale: 0.7,
      x: -50,
      y: -50,
    },
    enter: {
      opacity: 1,
      scale: 1,
      x: -50,
      y: -50,
    },
    leave: {
      opacity: 0,
      scale: 0.7,
      x: -50,
      y: -50,
    },
    config: config.gentle,
  })

  const nextSlide = useCallback(() => {
    clearTimeout(ref.current)
    set((state) => (state + 1) % items.length)
  }, [])

  useEffect(() => {
    ref.current = setTimeout(() => {
      set((state) => (state + 1) % items.length)
      clearTimeout(ref.current)
    }, 10000)
    return () => {
      clearTimeout(ref.current)
    }
  }, [index])

  return (
    <a className={item__slider__wrapper} onClick={nextSlide}>
      {transitions.map(({ item, props, key }) => (
        <animated.div
          key={key}
          className={item__slider__element}
          style={prepareStyles(props)}
        >
          <Slide {...item} />
        </animated.div>
      ))}
    </a>
  )
}

export default Slider
