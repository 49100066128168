import React, { useContext, useEffect } from "react"
import { useLocation } from "@reach/router"

import {
  Footer,
  Head,
  Header,
  Loader,
  DelayedRouter,
  CookiesModal,
} from "@components/layout"
import Main from "./Main"

import {
  app__header,
  app__footer,
  app__content,
  main__wrapper,
  main__wrapper__off,
} from "./layout.module.scss"

const Layout = ({ children, siteMetaData, seo, imBusy }) => {
  const location = useLocation()
  // useEffect(()=>{
  // 	window.scrollTo(0,1)
  // 	// window.scrollTo(0,0)
  // },[])
  return (
    <React.Fragment>
      <Head seo={seo} siteMetaData={siteMetaData} location={location} />
      <Loader show={imBusy} />
      <CookiesModal />
      <DelayedRouter>
        <Header className={app__header} />
        <Main>{children}</Main>
        <Footer />
      </DelayedRouter>
    </React.Fragment>
  )
}
export default Layout
