import React, { useState, useCallback } from "react"
import { Link } from "gatsby"
import { useSpring } from "react-spring/"
import { InteractWithUser } from "@components/logic"
import { useDelayedRedirect } from "@components/layout"

const animationStates = {
  idle: 1,
  hover: 0.95,
  clicking: 0.9,
  clicked: 1,
}

const AnimatedLink = ({
  className,
  activeClassName = "active",
  onClick = () => null,
  children,
  to = "/",
  style,
}) => {
  const redirect = useDelayedRedirect({ to, onClick })

  const [mode, setter] = useState(false)
  const { scale } = useSpring({
    config: { mass: 0.4, tension: 270, friction: 6 },
    scale: animationStates[mode] || 1,
  })

  const animationSwitch = useCallback(setter, [])

  return (
    <InteractWithUser
      onChangePhase={animationSwitch}
      style={{
        transform: scale.interpolate((s) => `scale3d(${s}, ${s}, ${s})`),
      }}
    >
      <Link
        onClick={redirect}
        className={className}
        activeClassName={activeClassName}
        style={style}
        to={to}
      >
        {children}
      </Link>
    </InteractWithUser>
  )
}

export default AnimatedLink
