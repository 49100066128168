import React from "react"
import { ResizedImage as Image } from "@components/shared"

import { item, item__image } from "./styles/galleryitem.module.scss"

const GalleryItem = (props) => {
  const { onClick } = props
  return (
    <div className={item}>
      <a className={item__image} onClick={onClick}>
        <Image {...props} size={"600w"} />
      </a>
    </div>
  )
}
export default GalleryItem
