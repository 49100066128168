import React, { useState } from "react"
import { Cart } from "@components/ecommerce"
import { Search } from "@components/theme"
import ModalMenu from "./ModalMenu"

import {
  nav,
  nav__list,
  nav__list__item,
  dropnav,
  dropnav__items,
} from "./header.module.scss"

function DropDown(props) {
  return (
    <nav className={dropnav}>
      <ul className={dropnav__items}>
        <li>
          <a href="#">Lorem ipsum dolor sit amet</a>
        </li>
        <li>
          <a href="#">Lorem ipsum dolor sit amet</a>
        </li>
        <li>
          <a href="#">Lorem ipsum dolor sit amet</a>
        </li>
        <li>
          <a href="#">Lorem ipsum dolor sit amet</a>
        </li>
      </ul>
    </nav>
  )
}

const NavMenu = () => {
  const [open, setOpen] = useState(false)

  return (
    <nav className={nav}>
      <ul className={nav__list}>
        <li className={nav__list__item}>
          {" "}
          <a href="/">STRONA GŁÓWNA</a>
        </li>
        <li className={nav__list__item}>
          {" "}
          USŁUGI
          <button
            onMouseEnter={() => setOpen(!open)}
            onMouseLeave={() => setOpen(!open)}
          >
            <i class="fas fa-caret-down"></i>
          </button>
          {open && <DropDown />}
        </li>
        <li className={nav__list__item}>
          <a href="/#">O NAS</a>
        </li>
        <li className={nav__list__item}>
          <a href="/blog">BLOG</a>
        </li>
        <li className={nav__list__item}>
          <a href="/contact">KONTAKT</a>
        </li>
      </ul>
    </nav>
  )
}

export default NavMenu
