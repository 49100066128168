import React from "react"
import PropTypes from "prop-types"

import { AnimatedLink } from "@components/shared"
import { withHtml } from "@components/logic"

import { arrows, arrows__right, arrows__left } from "./arrows.module.scss"

const arrowRight = (
  <div>
    <i class="fas fa-caret-right"></i>
  </div>
)

const Label = withHtml()

const DirectionArrows = ({
  nextItem,
  prevItem,
  nextCategory,
  prevCategory,
  linkModifier = ({ slug }) => `/${slug}/`,
  buttons = true,
}) => {
  if (!nextCategory || !prevCategory) {
    return (
      <div className={arrows}>
        <AnimatedLink className={arrows__left} to={linkModifier(nextItem)}>
          <Label>{nextItem.title}</Label>
        </AnimatedLink>
        <AnimatedLink className={arrows__right} to={linkModifier(prevItem)}>
          <Label>{prevItem.title + " "}</Label>
        </AnimatedLink>
      </div>
    )
  }
  return (
    <React.Fragment>
      <AnimatedLink>
        <Label>{nextItem.title}</Label>
      </AnimatedLink>
      <AnimatedLink>
        <Label>{prevItem.title}</Label>
      </AnimatedLink>
      <AnimatedLink>nextCategory</AnimatedLink>
      <AnimatedLink>prevCategory</AnimatedLink>
    </React.Fragment>
  )
}

DirectionArrows.propTypes = {
  nextItem: PropTypes.shape({
    title: PropTypes.string,
    slug: PropTypes.string,
  }),
  prevItem: PropTypes.shape({
    title: PropTypes.string,
    slug: PropTypes.string,
  }),
  nextCategory: PropTypes.shape({
    name: PropTypes.string,
    slug: PropTypes.string,
  }),
  prevCategory: PropTypes.shape({
    name: PropTypes.string,
    slug: PropTypes.string,
  }),
}

export default DirectionArrows
