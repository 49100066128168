import React, { useEffect, useRef } from "react"
import { Modal } from "@components/shared"
import { spinner__circle, loader } from "./loader.module.scss"

const Icon = () => (
  <svg
    width="30px"
    height="30px"
    xmlns="https://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid"
  >
    <rect x="0" y="0" width="100" height="100" fill="none"></rect>
    <circle
      className={spinner__circle}
      cx="50"
      cy="50"
      r="40"
      stroke="#999999"
      fill="none"
      strokeWidth="6"
      strokeLinecap="round"
    ></circle>
  </svg>
)

const Loader = ({ show }) => {
  const modal = useRef()

  useEffect(() => {
    if (typeof show === "boolean" && modal.current) {
      modal.current.toggle(show)
    }
  }, [show])

  if (show === false) {
    return null
  }

  return (
    <Modal
      ref={modal}
      title={"Proszę czekać"}
      close={false}
      lockBackground={true}
    >
      <div className={loader}>
        <Icon />
      </div>
    </Modal>
  )
}

export default Loader
