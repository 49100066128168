import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { AnimatedLink, ResizedImage as Image } from "@components/shared"
import logoimg from "./logoCapital.png"

import { logo } from "./header.module.scss"

const Logo = () => {
  const { WP } = useStaticQuery(graphql`
    query {
      WP {
        mediaItemBy(slug: "localhost_samfb") {
          sourceUrl
          srcSet
          altText
        }
      }
    }
  `)
  return <img src={logoimg} />
}

export default () => (
  <a className={logo} to={"/"}>
    <Logo />
  </a>
)
