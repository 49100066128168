import React, { useState, useEffect } from "react"
import { useLocation } from "@reach/router"
import { Cart, CartEmitter } from "../"
import { AnimatedSection, AnimatedLink } from "@components/shared"
import { Auth } from "@components/theme"
import { Logo } from "../Header/Logo"
import logoimg from "../Header/logoCapital.png"

import {
  app__footer__section,
  app__footer,
  app__footer__logo,
  app__footer__copyFoot,
  app__footer__contactInfo,
  app__footer__contactInfo__adress,
  app__footer__contactInfo__contact,
} from "./footer.module.scss"

const withDelay = (fn, delay = 2000) => {
  const timer = setTimeout(() => {
    fn()
    clearTimeout(timer)
  }, delay)
}

export const Footer = ({ className }) => {
  const { pathname } = useLocation()
  return (
    <div className={app__footer__section}>
      <div className={app__footer}>
        <div className={app__footer__logo}>
          <img src={logoimg} alt="" />
        </div>
        <div className={app__footer__contactInfo}>
          <div className={app__footer__contactInfo__adress}>
            <h5>ADRES</h5>
            <ul>
              <li>ul. Loremipsum 55,</li>
              <li>55-555 Lorem</li>
              <li>NIP : 999-999-99-99</li>
            </ul>
          </div>

          <div className={app__footer__contactInfo__contact}>
            <h5>KONTAKT</h5>
            <ul>
              <li>Tel.: 555-555-555</li>
              <li>Tel.: +48 55-55-555</li>
              <li>Mail: przykład@lorem.com</li>
            </ul>
          </div>
        </div>
      </div>
      <div className={app__footer__copyFoot}>
        &copy; Copyright 2020 by Localhost Group sp.z.o.o{" "}
      </div>
    </div>
  )
}

export default Footer
{
  /* <React.Fragment>
			<footer className={`${className} ${app__footer}`}>
				<div className={app__footer__item}>
					<Cart className={app__footer__link} />
				</div>

				{/*
				{pathname.includes('products') && (
					<React.Fragment>
						<div className={app__footer__item__break} />
						<div className={app__footer__item}>
							<a
								onClick={() => {
									CartEmitter.emit('addToCart')
								}}
								className={app__footer__link}
							>
								<i class="fas fa-shopping-cart"></i>
								{addedToCart ? (
									<i className='icofont-check' />
								) : (
									<i className='icofont-plus' />
								)}
							</a>
						</div>
					</React.Fragment>
				)}
				*/
}
// <div className={app__footer__item__break} />

// <div className={app__footer__item}>
// 	<Link to={'/checkout/'} className={app__footer__link}>
// 		<i className='icofont-credit-card' />
// 		<i className='icofont-double-right' />
// 	</Link>
// </div>
// <div className={app__footer__item__break} />

// <div className={app__footer__item}>
// 	{/*
// 	 <Auth className={app__footer__link} />
// 	 */}
// </div>
// 	</footer>
// 	<link
// 		async
// 		defer
// 		rel='stylesheet'
// 		type='text/css'
// 		charSet='UTF-8'
// 		href='https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css'
// 	/>
// 	<link
// 		async
// 		defer
// 		rel='stylesheet'
// 		type='text/css'
// 		href='https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css'
// 	/>
// </React.Fragment> */}
