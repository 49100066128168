import React, { useContext } from "react"
import { WindowCtx } from "../../../reduxWrapper"

import Logo from "./Logo"
import NavMenu from "./NavMenu"
import ContMenu from "./ContMenu"
import ModalMenu from "./ModalMenu"

import {
  header,
  header__scrolling,
  header__container,
  menu__container,
} from "./header.module.scss"

const Header = () => {
  const { y } = useContext(WindowCtx)
  return (
    <header className={[header, y > 5 ? header__scrolling : ""].join(" ")}>
      <div className={header__container}>
        <Logo />
        <div className={menu__container}>
          <ContMenu />
          <NavMenu />
          <ModalMenu />
        </div>
      </div>
    </header>
  )
}

export { Logo }
export default Header

// className={[header, y > 20 ? header__scrolling : ""].join(" ")}
