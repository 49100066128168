import React from "react"
import { TextInput } from "@components/shared"
// zmiana statusu

const CustomerForm = ({
  store,
  changeValue,
  onCreateCustomer,
  update = false,
  onCustomerUpdate,
}) => {
  return (
    <div className="auth-form">
      <form
        className="customer-form"
        onSubmit={(ev) => {
          ev.preventDefault()
        }}
      >
        {update === true ? (
          <div className="row justify-content-center">
            <div className="col-12 col-md-6">
              <h5>Witaj, {store.customer.email}! Zaktualizuj swoje dane</h5>
            </div>
          </div>
        ) : null}
        <h5>Dane do logowania</h5>
        <div className="row justify-content-center">
          <div className="col-12 col-md-6">
            <TextInput
              type={"email"}
              name="email"
              validate={"email"}
              placeholder="Adres email"
              value={store.customer.email}
              onChange={(ev) => {
                changeValue({
                  email: ev.target.value,
                  username: ev.target.value,
                })
              }}
            />
            <TextInput
              placeholder="Haslo"
              type="password"
              name="password"
              value={store.customer.password}
              validate={"text"}
              onChange={(ev) => {
                changeValue({
                  password: ev.target.value,
                })
              }}
            />
          </div>
        </div>
        <h5>Dane osobowe</h5>
        <div className="row justify-content-center">
          <div className="col-12 col-md-6">
            <TextInput
              placeholder="Imię"
              name="firstName"
              value={store.customer.first_name}
              validate={"text"}
              onChange={(ev) => {
                changeValue({
                  first_name: ev.target.value,
                })
              }}
            />
            <TextInput
              placeholder="Nazwisko"
              name="lastName"
              value={store.customer.last_name}
              onChange={(ev) => {
                changeValue({
                  last_name: ev.target.value,
                })
              }}
            />
            <TextInput
              placeholder="Telefon"
              name="phoneNo"
              validate={"number"}
              value={store.customer.phone}
              onChange={(ev) => {
                changeValue({
                  phone: ev.target.value,
                })
              }}
            />
          </div>
        </div>
        <h5>Dane do wysyłki</h5>
        <div className="row justify-content-center">
          <div className="col-12 col-md-6">
            <TextInput
              placeholder="Miasto"
              name="city"
              value={store.customer.city}
              onChange={(ev) => {
                changeValue({
                  city: ev.target.value,
                })
              }}
            />
            <TextInput
              placeholder="Ulica"
              name="address"
              value={store.customer.address_1}
              onChange={(ev) => {
                changeValue({
                  address_1: ev.target.value,
                })
              }}
            />
            <TextInput
              placeholder="Kod pocztowy"
              value={store.customer.postcode}
              name="zipCode"
              onChange={(ev) => {
                changeValue({
                  postcode: ev.target.value,
                })
              }}
            />
            <TextInput
              placeholder="Kraj"
              name="country"
              value={store.customer.country}
              onChange={(ev) => {
                changeValue({
                  country: ev.target.value,
                })
              }}
            />
          </div>
        </div>
        <div className="row justify-content-center">
          {update === true ? (
            <button className="app-button" onClick={onCustomerUpdate}>
              ZAKTUALIZUJ DANE
            </button>
          ) : (
            <button className="app-button" onClick={onCreateCustomer}>
              STWÓRZ KONTO
            </button>
          )}
        </div>
      </form>
    </div>
  )
}

export default CustomerForm
