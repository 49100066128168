import React from "react"
// import { QuantityChanger } from '@common'
import { ResizedImage as Image } from "@components/shared"
import { CartCtx, useActions, useContextState } from "@components/contexted"

import {
  summary__item,
  summary__col__wrap,
  summary__col,
  summary__item__remove,
  summary__item__description,
  summary__item__price,
  summary__quantity,
  summary__item__name,
} from "./styles/productsummaryitem.module.scss"

const ProductSummaryItem = ({
  product_id,
  quantity,
  image,
  name,
  value,
  changeProductQty,
}) => {
  const { removeFromCart } = useActions(CartCtx, ["removeFromCart"])

  return (
    <React.Fragment>
      <div className={summary__item}>
        <button
          className={summary__item__remove}
          style={{ opacity: 0 }}
          onClick={() => {}}
        >
          <i class="far fa-trash-alt"></i>
        </button>
        <div className={summary__col__wrap}>
          <Image {...image} size={"200w"} />
          <div className={summary__col}>
            <h5 className={summary__item__name}>{name}</h5>
          </div>

          <h5 className={summary__item__price}>{value} PLN</h5>
        </div>
        <button
          className={summary__item__remove}
          onClick={() => {
            removeFromCart({ product_id })
          }}
        >
          <i class="far fa-trash-alt"></i>
        </button>
      </div>
    </React.Fragment>
  )
}

export default ProductSummaryItem
