import React, { useContext } from "react"
import { WindowCtx } from "../../../reduxWrapper"

import { Cart } from "@components/ecommerce"
import { Search } from "@components/theme"
import ModalMenu from "./ModalMenu"

import {
  cont,
  cont__wrap,
  cont__wrap__none,
  cont__list,
  cont__list__item,
} from "./header.module.scss"

const ContMenu = () => {
  const { y } = useContext(WindowCtx)
  return (
    <div className={[cont__wrap, y > 5 ? cont__wrap__none : ""].join(" ")}>
      <nav className={cont}>
        <ul className={cont__list}>
          <li className={cont__list__item}>
            <i class="fas fa-mobile-alt"></i>555-555-555
          </li>
          <li className={cont__list__item}>
            <i class="far fa-envelope"></i>przykład@estyma.pl
          </li>
          <li className={cont__list__item}>
            <i class="fas fa-map-marker-alt"></i>ul. Lorem 3 55-555 Lorem
          </li>
        </ul>
      </nav>
    </div>
  )
}

export default ContMenu
