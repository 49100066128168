import React from "react"
import {
  ResizedImage as Image,
  AnimatedSection,
  AnimatedLink as Link,
} from "@components/shared"

import { withHtml } from "@components/logic"

import { wrapper, img, product__wave } from "./styles/singlecard.module.scss"

const Name = withHtml("h3")
const Description = withHtml("span", "span")
const PriceS = withHtml("h6")
const Price = withHtml("h5")

const ProductItem = ({
  name,
  shortDescription,
  image,
  regularPrice,
  salePrice,
  slug,
  ...props
}) => {
  const prices = (
    <React.Fragment>
      {salePrice ? (
        <React.Fragment>
          <PriceS>{salePrice}</PriceS>
          <Price>{regularPrice}</Price>
        </React.Fragment>
      ) : (
        <PriceS>{regularPrice}</PriceS>
      )}
    </React.Fragment>
  )

  return (
    <Link to={`/shop/products/${slug}/`}>
      <AnimatedSection className={wrapper}>
        <div className={product__wave}>
          <svg viewBox="0 0 370 550">
            <g id="Warstwa_2" data-name="Warstwa 2">
              <path
                d="M370,550V0C326.84,35.46,269.83,93.58,240.94,179.36c-17.17,51-14.2,81.42-21.82,118.79C205.59,364.52,158.15,455.34,0,550Z"
                fill="#3ec5d8"
              />
            </g>
          </svg>
        </div>

        <div className={img}>
          <Image {...image} size={"300w"} />
        </div>

        <Name>{name}</Name>
        {prices}
        <Description>{shortDescription}</Description>
      </AnimatedSection>
    </Link>
  )
}

export default ProductItem
