import { WPApi, PayUApi } from "@api"

const getCustomers = () => {
  return WPApi.getCustomers().then((data) => {
    return data
  })
}
const createCustomer = (customer) => {
  return WPApi.createCustomer(customer).then((data) => {
    return data
  })
}

const updateCustomer = (customer, id) => {
  return WPApi.updateCustomer(customer, id).then((data) => {
    return data
  })
}

const loginCustomer = (email, password) => {
  return WPApi.loginCustomer(email, password).then((data) => {
    return data
  })
}

export { createCustomer, getCustomers, loginCustomer, updateCustomer }
